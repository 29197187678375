import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SpeakingPage = () => (
  <Layout>
    <SEO title="Recordings" keywords={[`amy conroy`, `legal engineer`, `events`]} />
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=5Ysk7D2NgD4">University of Bristol</a></h4>
      <p>July 2021. Student Speaker at the University of Bristol Engineering Virtual Celebration.</p>
    </div>
    <div>
      <h4><a href = "https://www.instagram.com/tv/CKRoSu0p175/?utm_source=ig_web_copy_link">LegalBelle</a></h4>
      <p>Jan 2021. LegalBelle: 5 at 5 IGTV.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=bOq-vNjH2cs">KIIPSODES: Youtube</a></h4>
      <p>Jan 2021. openTenancy Open-Source Law.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=pRrXY2KTbPQ">DomTalksTech</a></h4>
      <p>Jan 2021. Law, Legal Tech, Samsung Adverts, Google's Future & More!.</p>
    </div>
    <div>
      <h4><a href = "https://www.instagram.com/tv/CGA0L6WJM4m/">BamLegal</a></h4>
      <p>Oct 2020. BamBytes: Introducing openTenancy.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=QXn35bNYWTs">Law Made Easy</a></h4>
      <p>Oct 2020. Legal Tech Q&A.</p>
    </div>
  </Layout>
)

export default SpeakingPage
